@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Mona+Sans:ital,wght@0,200..900;1,200..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Mona Sans", sans-serif;
}

.filter {
  filter: grayscale(100%);
}
